<template>
  <div id="student-component">
    <ul class="nav nav-tabs" id="special-order-tabs">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="UploadCSV"
          @click.prevent="tabActive('UploadCSV')"
          href="#"
          >Upload Report</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          id="Logs"
          data-toggle="tab"
          href="#"
          @click.prevent="tabActive('Logs')"
          >No. of Students Per Program</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link "
          id="ReportTracker"
          @click.prevent="tabActive('ReportTracker')"
          href="#"
          >Report Tracker</a
        >
      </li>

      <li class="nav-item ">
        <a
          class="nav-link disabled "
          id="SpecialOrders"
          @click.prevent="tabActive('SpecialOrders')"
          href="#"
          >Special Orders</a
        >
      </li>
    </ul>

    <!----------------------- // Tabs -------------------------------->

    <!----------------------- Component View ------------------------>
    <br />
    <component
      v-bind:is="currentTab"
      v-bind:hei_data="this.$attrs.hei_data"
      :hei_programs="hei_programs"
    >
    </component>

    <!----------------------- // Component View ------------------------>
  </div>
  <!-- // root container -->
</template>

<script>
import SpecialOrders from './HeiProfile_Students_SpecialOrders.vue';
import Logs from './HeiProfile_Students_Logs.vue';
import UploadCSV from './HeiProfile_Students_UploadCSV.vue';
import ReportTracker from './ReportTracker.vue';

export default {
  name: '',
  components: {
    SpecialOrders,
    Logs,
    UploadCSV,
    ReportTracker,
  },
  data() {
    return {
      currentTab: 'UploadCSV',
      specialOrderNumber: '',
      verifiedStudents: '',
      logs: '',
      students: [],
      logs_program: '',
      showTable: false,
      hei_programs: [],
      csvData: '',
      hei_programs: '',
    };
  },
  methods: {
    tabActive(element) {
      this.currentTab = element;
      $('#special-order-tabs .nav-link').removeClass('active');
      $('#' + element).addClass('active');
    },
    getHeiPrograms() {
      axios
        .get('api/hei-programs/' + this.$attrs.hei_data.code)
        .then((response) => {
          this.hei_programs = response.data;
        })
        .catch((error) => {
          toastr.error(error.response, 'Error');
        });
    },
  },
  mounted() {
    this.getHeiPrograms();
  },
};
</script>
