<template>
  <div>
    <div class="form-group">
      <label for="">Filter by area</label>
      <select
        name=""
        id=""
        class="form-control col-auto"
        v-model="filter"
        required
      >
        <option value="" selected>All</option>
        <option v-for="area in areas" :key="area.id" :value="area.id">
          {{ area.name }}
        </option>
      </select>
    </div>
    <table class="table table-sm table-bordered">
      <thead>
        <th>Code</th>
        <th>Area</th>
        <th>Finding/Observation</th>
        <th>Updated by</th>
        <th>Updated at</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <tr v-for="finding in filteredFindings" :key="finding.id">
          <th>{{ finding.code }}</th>
          <td>{{ finding.area_name }}</td>
          <td>{{ finding.description }}</td>
          <td>{{ finding.updated_by }}</td>
          <td>{{ finding.updated_at | dateTime }}</td>
          <td class="text-center px-0">
            <div class="btn-group">
              <button
                class="btn btn-sm btn-outline-secondary"
                @click="openUpdateFindingModal(finding)"
              >
                <i class="bi bi-pencil text-dark"></i>
              </button>
              <button
                class="btn btn-sm btn-outline-secondary"
                @click="deleteFinding(finding)"
              >
                <i class="bi bi-trash text-danger"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <UpdateFindingModal
      :areas="areas"
      ref="UpdateFindingModal"
      v-on:has-updates="getFindings"
    ></UpdateFindingModal>
  </div>
</template>

<script>
import UpdateFindingModal from './UpdateFindingModal.vue';

export default {
  name: 'ListOfFindings',
  components: {
    UpdateFindingModal,
  },
  data() {
    return {
      findings: [],
      filter: '',
      form: new Form({
        code: '',
        description: '',
        area_id: '',
      }),
      areas: [],
    };
  },
  methods: {
    getFindings() {
      axios.get('api/findings').then((res) => {
        this.findings = res.data;
      });
    },
    openUpdateFindingModal(finding) {
      this.$refs.UpdateFindingModal.open(finding);
    },
    getAreas() {
      axios.get('api/areas').then((res) => {
        this.areas = res.data;
      });
    },
    deleteFinding(finding) {
      if (
        confirm(
          `Are you sure you want to delete this item? \n ${finding.code} - ${finding.description}          
          \nWARNING: This action cannot be undone.
          `
        )
      ) {
        axios
          .delete('api/findings/' + finding.code)
          .then((res) => {
            this.getFindings();
          })
          .catch((err) => {});
      }
    },
  },
  computed: {
    filteredFindings() {
      let temp = this.findings;

      if (this.filter != '') {
        temp = temp.filter((finding) => {
          return finding.area_id == this.filter;
        });
      }

      return temp;
    },
  },
  mounted() {
    this.getFindings();
    this.getAreas();
  },
};
</script>
