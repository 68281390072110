<template>
  <div>
    <div class="modal fade" id="update-finding-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="updateFinding">
            <div class="modal-body">
              <div class="form-row">
                <div class="col-4">
                  <div class="form-group">
                    <label for="">Code</label>
                    <input
                      type="text"
                      class="form-control text-uppercase"
                      maxlength="3"
                      required
                      v-model="finding.code"
                      disabled
                    />
                    <small class="form-text text-muted"
                      >Limited to 3 letters</small
                    >
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Area</label>
                    <select
                      name=""
                      id=""
                      class="form-control"
                      v-model="finding.area_id"
                      required
                    >
                      <option value="" selected hidden disabled>Choose</option>
                      <option
                        v-for="area in areas"
                        :key="area.id"
                        :value="area.id"
                      >
                        {{ area.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="">Description</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder="Ex. Dean is not qualified"
                  v-model="finding.description"
                />
                <small class="form-text text-muted"
                  >Make it short and concise</small
                >
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button
                type="submit"
                id="update-finding-btn"
                class="btn btn-primary"
              >
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateFindingModal',
  props: {
    areas: {
      required: true,
    },
  },
  data() {
    return {
      finding: new Form({
        code: '',
        area_id: '',
        description: '',
      }),
    };
  },
  methods: {
    open(finding) {
      $('#update-finding-modal').modal('show');
      this.finding.fill(finding);
    },
    updateFinding() {
      let b = document.getElementById('update-finding-btn');
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Saving...`;
      b.disabled = true;
      axios
        .put('api/findings/' + this.finding.code, this.finding)
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          $('#update-finding-modal').modal('hide');
          this.$emit('has-updates');
          b.innerHTML = 'Save changes';
          b.disabled = false;
        })
        .catch((err) => {
          b.innerHTML = 'Save changes';
          b.disabled = false;
          toastr.error(err.response?.data.message, 'Error');
        });
    },
    mounted() {
      this.getAreas();
    },
  },
};
</script>
