<template>
  <div id="hei-profile-dashboard">
    <div class="row mt-1 my-0 px-0">
      <div class="col">
        <div class="row">
          <div class="col">
            <div class="">
              <div class="">
                <div class="form-row">
                  <div class="col-4 text-center my-auto">
                    <img
                      :src="hei_data.logo"
                      alt="School Logo"
                      class="rounded-circle"
                      width="150"
                      length="150"
                    />

                    <p>
                      Status:
                      <span class="text-success font-weight-bold">{{
                        hei_data.status
                      }}</span>
                    </p>
                  </div>
                  <div class="col-8">
                    <div class="text-center">
                      <p class="lead font-weight-bold  mb-0">
                        {{ hei_data.name }} ({{ hei_data.abbrev }})
                      </p>
                      <span class=""
                        >{{ hei_data.street }}, {{ hei_data.city }},
                        {{ hei_data.province }}, {{ hei_data.region }},
                        {{ hei_data.postal_code }}</span
                      >
                      <hr class="my-2" />
                    </div>

                    <div>
                      <div class="d-flex justify-content-between">
                        <div>
                          <i class="bi bi-telephone mr-1"></i>
                          {{ hei_data.contact_no }}
                        </div>

                        <div>
                          <i class="bi bi-globe mr-1"></i>
                          {{ hei_data.website }}
                        </div>

                        <div>
                          <i class="bi bi-envelope mr-1"></i>
                          {{ hei_data.email }}
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div class="col">
                        <label class="font-weight-bold my-0">HEI Code</label>
                        <p>{{ hei_data.code }}</p>
                      </div>

                      <div class="col">
                        <label class="font-weight-bold my-0">HEI Type</label>
                        <p>{{ hei_data.type }}</p>
                      </div>

                      <div class="col">
                        <label class="font-weight-bold my-0">Ownership</label>
                        <p>{{ hei_data.ownership }}</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <label class="font-weight-bold my-0">{{
                          hei_data.head_title
                        }}</label>
                        <p>{{ hei_data.president }}</p>
                      </div>

                      <div class="col">
                        <label class="font-weight-bold my-0"
                          >Established Year</label
                        >
                        <p>{{ hei_data.established_year }}</p>
                      </div>

                      <div class="col">
                        <label class="font-weight-bold my-0">Charter</label>
                        <p>{{ hei_data.charter }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeiProfile_Dashboard',
  props: ['hei_data'],
  data() {
    return {};
  },
  methods: {
    initializeEnrollmentGraph() {
      let element = document.getElementById('enrollment-graph');
      let enrollmentLineChart = new Chart(element, {
        type: 'line',
        //aspectRatio: 10,
        data: {
          labels: ['AY 2018-2019', 'AY 2019-2020', 'AY 2020-2021'],
          datasets: [
            {
              label: 'Number of Students',
              borderColor: 'rgb(255, 99, 132)',
              data: [100, 50, 250],
            },
            {
              label: 'Number of Students',
              borderColor: 'rgb(0, 0, 255)',
              data: [30, 70, 60],
            },
          ],
        },
      });
    },
    initializeCalendar() {
      let hei_calendar = document.getElementById('calendar-of-activities');
      let calendar = new Calendar(hei_calendar, {
        plugins: [dayGridPlugin, listPlugin],
        initialView: 'listMonth',
        aspectRatio: 2.5,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'listMonth,dayGridMonth',
        },
        events: [
          {
            id: 1,
            title: 'Enrollment of Students for Second Semester',
            start: '2021-02-18',
          },
          {
            id: 2,
            title: 'General Orientation for 1st Year Students',
            start: '2021-02-27',
          },
        ],
      });

      calendar.render();
    },
    editInformation() {
      $('#edit-information-modal').modal('show');
    },
  },

  mounted() {},
};
</script>
