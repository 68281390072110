<template>
  <div class="">
    <!----------------- Table of Special Orders -------------------->
    <table id="special-orders-table" class="table table-sm table-striped">
      <thead>
        <tr>
          <th>#</th>
          <th>SO Number</th>
          <th>Name of Institution</th>
          <th>Program</th>
          <th>No. of Students</th>
          <th>Date Issued</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="specialOrder in specialOrders" :key="specialOrder.id">
          <td>{{ specialOrder.id }}</td>
          <td>
            {{ specialOrder.so_number }}
          </td>
          <td>{{ specialOrder.name }}</td>
          <td>{{ specialOrder.program_name }}</td>

          <td>{{ specialOrder.total_no }}</td>
          <td>{{ specialOrder.created_at | standardDate }}</td>
          <td>
            <div class="btn-group">
              <button class="btn btn-outline-secondary btn-sm">
                <i class="bi bi-eye text-primary "></i>
              </button>
              <button class="btn btn-outline-secondary btn-sm">
                <i class="bi bi-printer text-success"></i>
              </button>
              <button class="btn btn-outline-secondary btn-sm">
                <i class="bi bi-trash text-danger"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!----------------- // Table of Special Orders -------------------->

    <div class="mt-3">
      <button
        class="btn btn-primary"
        @click.prevent="showSpecialOrderModal"
        disabled
      >
        Generate SO
      </button>
    </div>

    <!------------------------- Special Order Modal -------------------->
    <div class="modal fade" id="issue-special-order">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col pr-0">
                <div class="card">
                  <div class="card-body">
                    <select
                      v-model="specialOrderData.program_id"
                      @change="
                        fetchVerifiedStudents(specialOrderData.program_id)
                      "
                      name=""
                      id=""
                      class="custom-select"
                    >
                      <option value="" selected disabled hidden>
                        Select a program
                      </option>
                      <option
                        v-for="program in programs"
                        :key="program.id"
                        :value="program.id"
                      >
                        {{ program.program_name }}
                      </option>
                    </select>
                    <hr class="dotted" />
                    <div v-show="specialOrderData.program_id">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <i class="bi bi-search"></i>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="searchValue"
                          id="search-verified-students"
                          placeholder="Search Last Name or First Name"
                        />
                      </div>
                      <br />
                      <div
                        v-show="showTable"
                        style="max-height: 600px;"
                        class="overflow-auto"
                      >
                        <table
                          id="verified-students-table"
                          class="table table-bordered table-striped table-sm"
                        >
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Last Name</th>
                              <th>First Name</th>
                              <th>Middle Name</th>
                              <th>Ext Name</th>
                              <th>Reviewed by</th>
                              <th>Date</th>
                              <th>Actions</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="verifiedStudent in filteredVerifiedStudents"
                              :key="verifiedStudent.id"
                            >
                              <th scope="row">{{ verifiedStudent.id }}</th>
                              <td>{{ verifiedStudent.lastname }}</td>
                              <td>{{ verifiedStudent.firstname }}</td>
                              <td>{{ verifiedStudent.middlename }}</td>
                              <td>{{ verifiedStudent.extname }}</td>
                              <td>{{ verifiedStudent.reviewed_by }}</td>
                              <td>
                                {{ verifiedStudent.reviewed_on | dateTime }}
                              </td>

                              <td align="center">
                                <input
                                  v-model="specialOrderData.finalStudents"
                                  class=""
                                  type="checkbox"
                                  :value="verifiedStudent"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <h5>Special Order No: {{ specialOrderNumber }}</h5>
                    <span>Date: March 30, 2021</span>
                    <br />
                    <hr class="dotted" />
                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th>Student No</th>
                          <th>Last Name</th>
                          <th>First Name</th>
                          <th>Middle Name</th>
                          <th>Ext Name</th>
                          <th>Sex</th>
                        </tr>
                      </thead>
                      <tbody id="students-so-issuance">
                        <tr
                          v-for="finalStudent in specialOrderData.finalStudents"
                          :key="finalStudent.id"
                        >
                          <td scope="col">
                            {{ finalStudent.id }}
                          </td>
                          <td>{{ finalStudent.lastname }}</td>
                          <td>{{ finalStudent.firstname }}</td>
                          <td>{{ finalStudent.middlename }}</td>
                          <td>{{ finalStudent.extname }}</td>
                          <td>{{ finalStudent.sex }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <span class="text-center"
                      >Total Students:
                      <b>{{ specialOrderData.finalStudents.length }}</b></span
                    >
                    <hr class="dotted" />

                    <form @submit.prevent="generateSpecialOrder">
                      <div class="row">
                        <div class="col form-group">
                          <label for="">Graduation Date</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroupPrepend"
                                ><i class="bi bi-calendar"></i
                              ></span>
                            </div>
                            <input
                              v-model="specialOrderData.graduation_date"
                              type="date"
                              class="form-control"
                              placeholder="Graduation Date"
                              required
                            />
                          </div>
                        </div>

                        <div class="col form-group">
                          <label for="">Approved By</label>
                          <select
                            v-model="specialOrderData.approving_officer"
                            name=""
                            id=""
                            class="custom-select"
                            required
                          >
                            <option
                              selected
                              value="LEONIDA S. CALAGUI, Ph.D., CESO III"
                              >LEONIDA S. CALAGUI, Ph.D., CESO III</option
                            >
                          </select>
                        </div>
                      </div>
                      <br />
                      <p>Actions:</p>
                      <button
                        id="generate-button"
                        type="submit"
                        class="btn btn-primary mr-2"
                      >
                        Generate
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!------------------------- // Special Order Modal -------------------->
  </div>
</template>

<script>
export default {
  props: ['hei_data', 'programs'],
  data() {
    return {
      showTable: false,
      searchValue: '',
      specialOrders: '',
      specialOrderNumber: '',
      verifiedStudents: '',
      academic_years: '',
      semesters: '',
      specialOrderData: {
        hei_code: this.hei_data.code,
        program_id: '',
        finalStudents: [],
        graduation_date: '',
        approving_officer: '',
      },
    };
  },
  methods: {
    pushToFinalStudents(student) {
      this.finalStudents.push(student);
    },
    generateSpecialOrder() {
      let generate_btn = document.getElementById('generate-button');

      //send request

      if (this.specialOrderData['finalStudents'].length == 0) {
        toastr.error('The list is empty', 'Error');
      } else {
        generate_btn.innerHTML =
          '<span class="spinner-border spinner-border-sm mr-1"></span> Generating ...';

        axios
          .post('api/special-order', this.specialOrderData)
          .then((response) => {
            toastr.success('SO issued successfully.', 'Information');
            $('#issue-special-order').modal('hide');
            generate_btn.innerHTML = 'Generate';
            generate_btn.disabled = false;
            this.fetchSpecialOrders();
          })
          .catch((error) => {
            toastr.error(error.response.data.message, 'Error');
          });
      }
    },
    fetchVerifiedStudents(program_id) {
      axios
        .get('api/get-verified-students/' + program_id)
        .then((response) => {
          this.verifiedStudents = response.data;
          this.verifiedStudents.length
            ? (this.showTable = true)
            : toastr.error('No verified students found.', 'Information');
        })
        .catch((error) => {
          toastr.error(error.response.data.message, 'Error!');
        });
    },
    showSpecialOrderModal() {
      $('#issue-special-order').modal('show');
    },

    fetchSpecialOrders() {
      axios
        .post('api/fetch-so')
        .then((response) => {
          this.specialOrders = response.data;
          /*   $(document).ready(() => {
            $('#special-orders-table').DataTable({
              order: [[0, 'desc']],
              destroy: true,
            });
          }); */
        })
        .catch((error) => {
          toastr.error(error.response.data.message, 'Error');
        });
    },
  },
  computed: {
    filteredVerifiedStudents() {
      let tempVerifiedStudents = this.verifiedStudents;

      if (this.searchValue != '') {
        tempVerifiedStudents = tempVerifiedStudents.filter((student) => {
          return (
            student.lastname
              .toUpperCase()
              .includes(this.searchValue.toUpperCase()) ||
            student.firstname
              .toUpperCase()
              .includes(this.searchValue.toUpperCase())
          );
        });
      }

      return tempVerifiedStudents;
    },
  },
  watch: {
    /* verifiedStudents: function() {
      this.$nextTick(function() {
        var dt = $('#verified-students-table').DataTable({
          destroy: true,
          data: this.verifiedStudents,
          columns: [
            { title: 'Student No', data: 'id' },
            { title: 'Last Name', data: 'lastname' },
            { title: 'First Name', data: 'firstname' },
            { title: 'Middle Name', data: 'middlename' },
            {
              title: 'Actions',
              className: 'text-center',
              render: function() {
                return '<input type="checkbox"/>';
              },
            },
          ],
        });

        // add event listeners on checkbox
        var _finalStudents = this.specialOrderData.finalStudents;
        $('#verified-students-table tbody').on('click', 'input', function() {
          var data = dt.row($(this).parents('tr')).data();
          if ($(this).is(':checked')) {
            //push student to finalStudents array
            _finalStudents.push(data);
          } else {
            //find index and remove student on the array
            let index = _finalStudents.findIndex(
              (student) => student.id === data.id
            );
            _finalStudents.splice(index, 1);
          }
        });
      }); 
    },*/
    /*  specialOrders: function() {
      this.$nextTick(function() {
        $('#special-orders-table').DataTable({
          data: this.specialOrders,
          destroy: true,
          order: [[0, 'desc']],
          columns: [
            { title: 'No', data: 'id' },
            {
              title: 'Special Order No',
              data: 'so_number',
            },
            { title: 'Name of Institution', data: 'name' },
            { title: 'Program', data: 'program_name' },

            { title: 'No. of Students', data: 'total_no' },
            { title: 'Date Issued', data: 'created_at' },
          ],
        });
      });
    }, */
  },

  mounted() {
    this.fetchSpecialOrders();

    //event listener for special-order modal when closed
    $('#issue-special-order').on('hidden.bs.modal', () => {
      this.specialOrderData.finalStudents = [];
      this.verifiedStudents = [];
      this.specialOrderData.program_id = '';
      $('table').off('#verified-students-table');
    });
  },
};
</script>
