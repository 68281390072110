<template>
  <div class="">
    <div>
      <table class="table table-bordered table-sm">
        <tbody class="text-center">
          <tr>
            <th>Programs</th>
            <th colspan="6">2021</th>
            <th colspan="6">2022</th>
            <th colspan="6">2023</th>
          </tr>
          <tr>
            <td></td>
            <td>
              Admin
            </td>
            <td>Faculty</td>
            <td>Curriculum</td>
            <td>Library</td>
            <td>Lab</td>
            <td>Others</td>
            <td>
              Admin
            </td>
            <td>Faculty</td>
            <td>Curriculum</td>
            <td>Library</td>
            <td>Lab</td>
            <td>Others</td>
            <td>
              Admin
            </td>
            <td>Faculty</td>
            <td>Curriculum</td>
            <td>Library</td>
            <td>Lab</td>
            <td>Others</td>
          </tr>
          <tr>
            <td align="left">Bachelor of Information Technology</td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td></td>
            <td>&#10003;</td>
            <td></td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td></td>
            <td>&#10003;</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td align="left">Bachelor of Science in Elementary Education</td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td></td>
            <td>&#10003;</td>
            <td></td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td></td>
            <td>&#10003;</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td align="left">
              Bachelor of Science in Agricultural Engineering
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>&#10003;</td>
            <td></td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td>&#10003;</td>
            <td></td>
            <td>&#10003;</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MonitoringReports',
};
</script>
