<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="">Select program</label>
          <select
            v-model="selected_program_id"
            class="form-control"
            @change="getUploadHistory"
            name=""
            id=""
          >
            <option value="" selected hidden disabled
              >Choose a program offered by the HEI</option
            >
            <option
              v-for="hei_program in hei_programs"
              :key="hei_program.hei_program_id"
              :value="hei_program.program_id"
              >{{ hei_program.program_name }}</option
            >
          </select>
        </div>
      </div>
      <div class="col my-auto">
        <div v-if="loader" class="d-flex align-items-center ">
          <strong class="text-primary">Collecting upload history...</strong>
          <div
            class="spinner-border spinner-border-sm text-primary ml-auto"
          ></div>
        </div>
      </div>
    </div>

    <hr class="dotted" />

    <table
      v-show="showTable"
      class="table table-striped table-bordered table-sm"
    >
      <thead>
        <tr class="text-center">
          <th>#</th>
          <th>Program</th>
          <th>Academic Year</th>
          <th>Semester</th>
          <th>Total Students</th>
          <th>Uploaded on (EL)</th>
          <th>Grades</th>
          <th>Uploaded on (PL)</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(log, index) in logs" :key="log.id" class="text-center">
          <td>{{ index + 1 }}</td>
          <td>{{ log.program_name }}</td>
          <td>{{ log.academic_year }}</td>
          <td>{{ log.semester }}</td>
          <td>{{ log.total_students }}</td>
          <td>{{ log.created_at | standardDate }}</td>
          <td>
            <span v-show="log.pl_uploaded_at" class="text-success"
              >Submitted</span
            >
            <span v-show="!log.pl_uploaded_at" class="text-danger"
              >Not submitted</span
            >
          </td>
          <td>
            <span v-show="log.pl_uploaded_at">{{
              log.pl_uploaded_at | standardDate
            }}</span>
          </td>
          <td class="text-center">
            <button
              class="btn btn-danger btn-sm"
              @click.prevent="deleteSemester(log, $event)"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['hei_programs'],
  data() {
    return {
      loader: false,
      showTable: false,
      logs: '',
      selected_program_id: '',
    };
  },
  methods: {
    getUploadHistory() {
      this.loader = true;
      axios
        .post('api/get-logs', {
          hei_code: this.$attrs.hei_data.code,
          selected_program_id: this.selected_program_id,
        })
        .then((response) => {
          this.logs = response.data;
          this.logs.length != 0
            ? (this.showTable = true)
            : toastr.error('No results found.', 'Information');
          this.loader = false;
        })
        .catch((error) => {
          alert(error);
        });
    },

    deleteSemester(data, event) {
      let deleteButton = event.target;
      deleteButton.innerHTML = `<div class="spinner-border spinner-border-sm mr-1" role="status"></div> Deleting`;
      deleteButton.disabled = true;

      axios
        .post('api/course/destroy', {
          data: data,
          hei_code: this.$attrs.hei_data.code,
        })
        .then((response) => {
          toastr.success('Deleted successfully.', 'Information');
          deleteButton.innerHTML = 'Delete';
          deleteButton.disabled = false;
          this.getUploadHistory();
        })
        .catch((error) => {
          toastr.error(error.response.data.message, 'Error');
          deleteButton.innerHTML = 'Delete';
          deleteButton.disabled = false;
        });
    },
  },
  mounted() {},
};
</script>
